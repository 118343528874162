import type { JSONSchemaType } from 'ajv';

export const strictBooleanSchema: JSONSchemaType<boolean> = {
  type: 'boolean',
};

export const nullableBooleanSchema: JSONSchemaType<boolean> = {
  type: 'boolean',
  nullable: true,
};
